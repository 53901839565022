import React, { useState, useEffect } from 'react';
import Layout from './Layout'; // Importa il layout
import { Container, TextField, Button, Typography, Box, MenuItem, Select, InputLabel, FormControl, Grid } from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "../context/SnackbarContext";

const MovementForm = () => {
    const navigate = useNavigate();
    const { handle_snackbar_open } = useSnackbar();

    const [dateOut, setDateOut] = useState('');
    const [destination, setDestination] = useState('');
    const [curator, setCurator] = useState('');
    const [responsible, setResponsible] = useState('');
    const [artwork, setArtwork] = useState(''); // Stato per il quadro selezionato
    const [artworks, setArtworks] = useState([]); // Stato per la lista dei quadri
    const [error, setError] = useState('');

    // Simulazione del caricamento dei quadri
    useEffect(() => {
        // In un caso reale, potresti chiamare un'API per ottenere i quadri
        const fetchArtworks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();
                setArtworks(data); // Imposta l'elenco dei quadri
            } catch (error) {
                console.error('Errore nel caricamento dei quadri:', error);
            }
        };

        fetchArtworks();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/movements`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    exit_date: dateOut,
                    destination,
                    curator_name: curator,
                    manager_name: responsible,
                    artwork_id: artwork,
                }),
            });

            if (response.ok) {
                handle_snackbar_open('Movimento aggiunto con successo!');
                navigate('/movements');
            } else {
                handle_snackbar_open('Errore nell\'aggiungere il movimento');
            }
        } catch (error) {
            setError('Errore nell\'aggiungere il movimento. Riprova.');
        }
    };

    return (
        <Layout>
            <Container maxWidth="sm">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Aggiungi un Movimento
                    </Typography>

                    {error && (
                        <Typography color="error" variant="body2" gutterBottom>
                            {error}
                        </Typography>
                    )}

                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Data Uscita"
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    value={dateOut}
                                    onChange={(e) => setDateOut(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    required
                                />
                            </Grid>

                            {/* Campo Seleziona Quadro subito dopo Data Uscita */}
                            <Grid item xs={12}>
                                <FormControl fullWidth required>
                                    <InputLabel id="artwork-label">Seleziona Quadro</InputLabel>
                                    <Select
                                        labelId="artwork-label"
                                        value={artwork}
                                        onChange={(e) => setArtwork(e.target.value)}
                                        label="Seleziona Quadro"
                                    >
                                        {artworks.map((art) => (
                                            <MenuItem key={art.id} value={art.id}>
                                                {art.name} - {art.creation_date}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Destinazione"
                                    variant="outlined"
                                    fullWidth
                                    value={destination}
                                    onChange={(e) => setDestination(e.target.value)}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Curatore"
                                    variant="outlined"
                                    fullWidth
                                    value={curator}
                                    onChange={(e) => setCurator(e.target.value)}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Responsabile"
                                    variant="outlined"
                                    fullWidth
                                    value={responsible}
                                    onChange={(e) => setResponsible(e.target.value)}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    Aggiungi Movimento
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </Layout>
    );
};

export default MovementForm;
