import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Box, Button, IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from "../context/AuthContext";
import { SnackbarProvider } from '../context/SnackbarContext';

const Layout = ({ children }) => {
    const { setIsAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/login');
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <SnackbarProvider>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={toggleDrawer(true)}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                        Dashboard
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '10px' }}>
                        <Button color="inherit" component={Link} to="/artworks/new">
                            Inserisci Quadro
                        </Button>
                        <Button color="inherit" component={Link} to="/movements/new">
                            Inserisci Movimento
                        </Button>
                        <Button color="inherit" component={Link} to="/artworks">
                            Visualizza Opere
                        </Button>
                        <Button color="inherit" component={Link} to="/movements">
                            Visualizza Movimenti
                        </Button>
                    </Box>
                    <IconButton color="inherit" onClick={handleMenuOpen} sx={{ marginLeft: 'auto' }}>
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem component={Link} to="/account-settings" onClick={handleMenuClose}>
                            Account Settings
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); handleLogout(); }}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <ListItem button component={Link} to="/artworks/new">
                        <ListItemText primary="Inserisci Quadro" />
                    </ListItem>
                    <ListItem button component={Link} to="/movements/new">
                        <ListItemText primary="Inserisci Movimento" />
                    </ListItem>
                    <ListItem button component={Link} to="/artworks">
                        <ListItemText primary="Visualizza Opere" />
                    </ListItem>
                    <ListItem button component={Link} to="/movements">
                        <ListItemText primary="Visualizza Movimenti" />
                    </ListItem>
                </List>
            </Drawer>
            <Container style={{ padding: '20px 0px 5px' }}>
                {children}
            </Container>
        </SnackbarProvider>
    );
};

export default Layout;
