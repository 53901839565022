import React from 'react';
import { Typography, Box } from '@mui/material';
import Layout from './Layout'; // Importa il layout

const Dashboard = () => {
    return (
        <Layout>
            <Box sx={{ marginTop: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    Benvenuto nella tua Dashboard!
                </Typography>
                <Typography variant="body1">
                    Qui puoi gestire le tue opere e i movimenti.
                </Typography>
            </Box>
        </Layout>
    );
};

export default Dashboard;
