import React, { createContext, useState, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Crea il contesto
const SnackbarContext = createContext();

// Funzione per usare il contesto più facilmente
export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

// Provider per gestire lo stato dello Snackbar
export const SnackbarProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success'); // success, error, warning, info

    const handle_snackbar_open = (msg, sev = 'success') => {
        setMessage(msg);
        setSeverity(sev);
        setOpen(true);
    };

    const handle_snackbar_close = () => {
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={{ handle_snackbar_open }}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handle_snackbar_close}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handle_snackbar_close} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
