import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';

const AccountSettings = () => {
    const [id, setId] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Carica l'email dell'utente al montaggio del componente
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/profile`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setId(response.data.id);
                setEmail(response.data.email);
                setName(response.data.name);
            } catch (err) {
                setError("Errore nel recupero dei dati dell'utente.");
            }
        };

        fetchUserData();
    }, []);

    // Funzione per aggiornare i dati dell'utente
    const handleUpdate = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Le password non coincidono.");
            setMessage('');
            return;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/user/update/${id}`, {
                name,
                password,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });

            if (response.status === 200) {
                setMessage("Dati aggiornati con successo!");
                setError('');
            } else {
                setError("Errore nell'aggiornamento dei dati.");
                setMessage('');
            }
        } catch (error) {
            setError(error.response?.data?.message || "Si è verificato un problema.");
            setMessage('');
        }
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Gestisci il tuo account
            </Typography>
            <Box component="form" onSubmit={handleUpdate} sx={{ mt: 2 }}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="email"
                    value={email}
                    slotProps={{
                        inputLabel: {
                            readOnly: true,
                        }
                    }}
                />
                <TextField
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    label="Nuova Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    label="Conferma Nuova Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Aggiorna
                </Button>
            </Box>
            {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        </Container>
    );
};

export default AccountSettings;
