import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'; // Make sure this imports correctly
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Artworks from './components/ArtworkList';
import Movements from './components/MovementList';
import ArtworkForm from './components/ArtworkForm';
import MovementForm from './components/MovementForm';
import AccountSettings from './components/AccountSettings';
import NotFound from './components/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import { SnackbarProvider } from './context/SnackbarContext';

function App() {
    console.log("env: " + process.env.NODE_ENV, "  API: " + process.env.REACT_APP_API_URL)

    return (
        <SnackbarProvider>
            <Router>
                <AuthProvider> {/* Move AuthProvider inside Router */}
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute>
                                    <Dashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/artworks"
                            element={
                                <ProtectedRoute>
                                    <Artworks />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/movements"
                            element={
                                <ProtectedRoute>
                                    <Movements />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/artworks/:id?"
                            element={
                                <ProtectedRoute>
                                    <ArtworkForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/movements/new"
                            element={
                                <ProtectedRoute>
                                    <MovementForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/account-settings"
                            element={
                                <ProtectedRoute>
                                    <AccountSettings />
                                </ProtectedRoute>
                            }
                        />

                        {/* Rotta per le pagine non trovate */}
                        <Route component={NotFound} />
                    </Routes>
                </AuthProvider>
            </Router>
        </SnackbarProvider>
    );
}

export default App;
