import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import Layout from "./Layout";
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../context/SnackbarContext';

const ArtworkForm = () => {
    const navigate = useNavigate();
    const { handle_snackbar_open } = useSnackbar();
    const { id } = useParams();

    const [name, setName] = useState('');
    const [creationDate, setCreationDate] = useState('');
    const [base, setBase] = useState('');
    const [height, setHeight] = useState('');
    const [technique, setTechnique] = useState('');
    const [photo, setPhoto] = useState(null);
    const [photoPreview, setPhotoPreview] = useState(null);
    const [percentageMarkup, setPercentageMarkup] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');

    // Funzione per caricare l'immagine in base64
    const fetchImage = async (photoId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/image/${photoId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                const json = await response.json();
                setPhotoPreview(`data:image/jpeg;base64,${json.image}`);
            } else {
                setPhotoPreview(null); // Gestire il caso in cui l'immagine non è trovata
            }
        } catch (error) {
            console.error('Errore nel caricamento dell\'immagine:', error);
        }
    };

    // Carica i dati dell'opera se è presente un id (modalità modifica)
    useEffect(() => {
        if (id && id!=='new') {
            const fetchArtwork = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks/${id}`, {
                        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                    });
                    const data = await response.json();
                    const creation_date = new Date(data.creation_date).toISOString().split('T')[0];
                    setName(data.name);
                    setCreationDate(creation_date);
                    setBase(data.width);
                    setHeight(data.height);
                    setTechnique(data.technique);
                    setPercentageMarkup(data.percentage_markup);
                    setPhotoUrl(data.photo);
                    await fetchImage(data.photo); // Carica l'immagine associata all'opera
                } catch (error) {
                    console.error('Errore nel caricamento del quadro:', error);
                }
            };
            fetchArtwork().then(() => {});
        }
    }, [id]);

    const handlePhotoUpload = async (file) => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert('Token non trovato, per favore effettua di nuovo il login.');
            return null;
        }
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formData
            });
            const data = await response.json();
            setPhotoUrl(data.hash);
            return data.hash;
        } catch (error) {
            console.error('Errore nel caricamento della foto:', error);
            return null;
        }
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        setPhoto(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (creationDate < 1900 || creationDate > 2100) {
            alert('Per favore inserisci un anno valido (tra 1900 e 2100).');
            return;
        }

        let uploadedPhotoUrl = photoUrl;
        if (photo) {
            uploadedPhotoUrl = await handlePhotoUpload(photo);
            if (!uploadedPhotoUrl) {
                alert('Errore nel caricamento della foto');
                return;
            }
        }

        const artworkData = {
            name,
            creation_date: creationDate,
            width: base,
            height,
            technique,
            photo: uploadedPhotoUrl,
            percentage_markup: percentageMarkup
        };

        const method = id !== 'new' ? 'PUT' : 'POST';
        const url = id !== 'new' ? `${process.env.REACT_APP_API_URL}/artworks/${id}` : `${process.env.REACT_APP_API_URL}/artworks`;

        const response = await fetch(url, {
            method,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(artworkData)
        });

        if (response.ok) {
            const successMessage = id ? 'Quadro aggiornato con successo!' : 'Quadro aggiunto con successo!';
            handle_snackbar_open(successMessage);
            navigate('/artworks');
        } else {
            console.log(response);
            handle_snackbar_open('Errore nel salvataggio del quadro');
        }
    };

    return (
        <Layout>
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    {id ? 'Modifica Quadro' : 'Aggiungi Quadro'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <TextField fullWidth label="Nome dell'opera" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            label="Anno di realizzazione"
                            type="number"
                            variant="outlined"
                            value={creationDate}
                            onChange={(e) => setCreationDate(e.target.value)}
                            required
                        />
                    </Box>
                    <Box mb={2} display="flex" justifyContent="space-between">
                        <TextField label="Base (cm)" variant="outlined" value={base} onChange={(e) => setBase(e.target.value)} required style={{ flex: 1, marginRight: '8px' }} />
                        <TextField label="Altezza (cm)" variant="outlined" value={height} onChange={(e) => setHeight(e.target.value)} required style={{ flex: 1 }} />
                    </Box>
                    <Box mb={2}>
                        <TextField fullWidth label="Tecnica di realizzazione" variant="outlined" value={technique} onChange={(e) => setTechnique(e.target.value)} required />
                    </Box>
                    <Box mb={2}>
                        <TextField fullWidth label="Maggiorazione percentuale" variant="outlined" value={percentageMarkup} onChange={(e) => setPercentageMarkup(e.target.value)} />
                    </Box>
                    <Box mb={2}>
                        <input type="file" accept="image/*" onChange={handlePhotoChange} />
                        {photoPreview && (
                            <Box mt={2}>
                                <img src={photoPreview} alt="Anteprima" style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }} />
                            </Box>
                        )}
                    </Box>
                    <Button variant="contained" color="primary" type="submit">
                        {id ? 'Aggiorna Quadro' : 'Aggiungi Quadro'}
                    </Button>
                </form>
            </Container>
        </Layout>
    );
};

export default ArtworkForm;
