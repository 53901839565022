// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => { // Use named export
    const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track authentication
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token || isTokenExpired(token)) {
            setIsAuthenticated(false);
            navigate('/login');
        } else {
            setIsAuthenticated(true);
        }
    }, [navigate]);

    const isTokenExpired = (token) => {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiry = payload.exp * 1000;
        return Date.now() >= expiry;
    };

    const login = () => {
        setIsAuthenticated(true);
        navigate('/dashboard');
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('token'); // Clear the token from local storage
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
