import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Usa il contesto per verificare lo stato di autenticazione

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth(); // Controlla se l'utente è autenticato

    if (!isAuthenticated) {
        // Se non è autenticato, reindirizza alla pagina di login
        return <Navigate to="/login" />;
    }

    // Se è autenticato, mostra la pagina richiesta
    return children;
};

export default ProtectedRoute;
