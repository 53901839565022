import React from 'react';
import { Typography, Box } from '@mui/material';
import Layout from './Layout';

const NotFound = () => {
    return (
        <Layout>
            <Box sx={{ marginTop: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    Pagina non trovata
                </Typography>
            </Box>
        </Layout>
    );
};

export default NotFound;
