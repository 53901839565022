import React, { useEffect, useState } from 'react';
import {Container, Card, CardMedia, CardContent, Typography, Divider, Button, Grid2} from '@mui/material';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate per reindirizzare

const ArtworkList = () => {
    const [artworks, setArtworks] = useState([]);
    const [imageUrls, setImageUrls] = useState({});
    const navigate = useNavigate(); // Inizializza useNavigate per navigare tra le pagine

    useEffect(() => {
        const fetchArtworks = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setArtworks(data);
            await fetchImages(data); // Recupera le immagini dopo aver ottenuto i dati
        };

        fetchArtworks().then(() => {});
    }, []);

    const fetchImages = async (artworks) => {
        const newImageUrls = {};

        for (const artwork of artworks) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/image/${artwork.photo}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                const json = await response.json();
                newImageUrls[artwork.photo] = `data:image/jpeg;base64,${json.image}`;
            } else {
                newImageUrls[artwork.photo] = null; // Gestire caso in cui non si trova l'immagine
            }
        }

        setImageUrls(newImageUrls);
    };

    // Funzione per formattare la data nel formato YYYY/mm/dd
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
    };

    // Funzione per gestire la modifica di un'opera d'arte
    const handleEdit = (artworkId) => {
        navigate(`/artworks/${artworkId}`); // Reindirizza alla pagina di modifica dell'opera
    };

    // Funzione per gestire la rimozione di un'opera d'arte
    const handleDelete = async (artworkId) => {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare quest'opera?");
        if (confirmDelete) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks/${artworkId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                // Aggiorna la lista delle opere dopo la cancellazione
                setArtworks(artworks.filter(artwork => artwork.id !== artworkId));
            } else {
                alert("Errore nell'eliminazione dell'opera.");
            }
        }
    };

    return (
        <Layout>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom align="center">
                    Elenco delle Opere
                </Typography>
                <Grid2 container spacing={4}>
                    {artworks.map((artwork) => (
                        <Grid2 item xs={12} sm={6} md={4} key={artwork.id}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={imageUrls[artwork.photo]}
                                    alt={artwork.name}
                                    sx={{
                                        objectFit: "cover",
                                        margin: '0'
                                    }}
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {artwork.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Data di Realizzazione: {formatDate(artwork.date)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Dimensioni: {artwork.dimensions} cm
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Tecnica: {artwork.technique}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Maggiorazione Percentuale: {artwork.markup}%
                                    </Typography>

                                    <Divider style={{ margin: '20px 0' }} />

                                    {artwork.lastMovement.exit_date ? (
                                        <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                Posizione attuale
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Data uscita: {formatDate(artwork.lastMovement.exit_date)}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Destinazione: {artwork.lastMovement.destination}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Curatore: {artwork.lastMovement.curator_name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Data rientro: {artwork.lastMovement.return_date}
                                            </Typography>
                                        </div>
                                    ) : (
                                        <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
                                            <Typography variant="body2" color="text.secondary" align="center">
                                                Nessun movimento registrato.
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">&nbsp;</Typography>
                                            <Typography variant="body2" color="text.secondary">&nbsp;</Typography>
                                            <Typography variant="body2" color="text.secondary">&nbsp;</Typography>
                                            <Typography variant="body2" color="text.secondary">&nbsp;</Typography>
                                        </div>
                                    )}

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEdit(artwork.id)}
                                        style={{ marginTop: '10px', marginRight: '10px' }}
                                    >
                                        Modifica
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleDelete(artwork.id)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Elimina
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid2>
                    ))}
                </Grid2>
            </Container>
        </Layout>
    );
};

export default ArtworkList;
